import React from 'react'
import Sidebar from '@components/easySign/sidebar'
import Layout from '@components/layout/layout'
import * as stylesContent from '@styles/layout/content.module.css'
import Seo from '@components/setting-site/seo'

const Create = () => (
  <Layout>
    <Seo title="Developers Stupendo | EasySign" />
    <div className={stylesContent.body}>
      <div className={stylesContent.container}>
        <Sidebar />
      </div>
      <embed
        src="https://decfiles.s3.us-east-1.amazonaws.com/easySign/api_creacion_de_firma_easysign.pdf"
        className={stylesContent.pdf}
      />
    </div>
  </Layout>
)

export default Create
